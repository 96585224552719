import React from "react";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <>
      <Routes>
        <Route path = '/' element = {<Navbar/>}/>
      </Routes>
      </>

    </Router>
  );
}

export default App;
