import React from 'react'

function Navbar () {
  return (
    <>
      {/* <!--header section start --> */}
      <div class='header_section'>
        <div class='container'>
          <nav class='navbar navbar-dark bg-dark'>
            <a class='logo' href=''>
              <img style={{ width: '100%' }} src='./assets/images/logo.png' />
            </a>
            <button
              class='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarsExample01'
              aria-controls='navbarsExample01'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span class='navbar-toggler-icon'></span>
            </button>
            <div class='collapse navbar-collapse' id='navbarsExample01'>
              <ul class='navbar-nav mr-auto'>
                <li class='nav-item'>
                  <a class='nav-link' href=''>
                    Home
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='#about'>
                    About
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='#services'>
                    Services
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="#blogs">
                    Blog
                  </a>
                </li> */}
                <li class='nav-item'>
                  <a class='nav-link' href='#client'>
                    Client
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='#contact'>
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* <!--banner section start --> */}
        <div class='banner_section layout_padding'>
          <div id='my_slider' class='carousel slide' data-ride='carousel'>
            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <div class='container'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='taital_main'>
                        <h4 class='banner_taital'>Our Services</h4>
                        <p class='banner_text'>
                          We offer a wide range of IT services, including
                          website design and development, software development,
                          IT consulting, cloud computing, IT security, and
                          managed IT services.
                        </p>
                        {/* <div class="read_bt"><a href="#">Read More</a></div> */}
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div>
                        <img src='./assets/images/img-1.png' class='image_1' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='carousel-item'>
                <div class='container'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='taital_main'>
                        <h4 class='banner_taital'>Our Expertise</h4>
                        <p class='banner_text'>
                          Our team of experienced and qualified IT professionals
                          has a wealth of experience in a variety of IT
                          disciplines. We're always up-to-date on the latest
                          technologies and are committed to providing our
                          clients with the best possible solutions.
                        </p>
                        {/* <div class="read_bt"><a href="#">Read More</a></div> */}
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div>
                        <img src='./assets/images/img-1.png' class='image_1' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='carousel-item'>
                <div class='container'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='taital_main'>
                        <h4 class='banner_taital'>Software Solutions</h4>
                        <p class='banner_text'>
                          We provide comprehensive software solutions for
                          businesses of all sizes. We can help you with
                          everything from website development to mobile app
                          development to enterprise software development.
                        </p>
                        {/* <div class="read_bt"><a href="#">Read More</a></div> */}
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div>
                        <img src='./assets/images/img-1.png' class='image_1' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              class='carousel-control-prev'
              href='#my_slider'
              role='button'
              data-slide='prev'
            >
              <i class=''>
                <img src='./assets/images/left-icon.png' />
              </i>
            </a>
            <a
              class='carousel-control-next'
              href='#my_slider'
              role='button'
              data-slide='next'
            >
              <i class=''>
                <img src='./assets/images/right-icon.png' />
              </i>
            </a>
          </div>
        </div>
        {/* <!--banner section end --> */}
      </div>
      {/* <!--header section end -->
      <!--about section start --> */}
      <div id='about' class='about_section layout_padding'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6'>
              <div class='image_2'>
                <img src='./assets/images/img-2.png' />
              </div>
            </div>
            <div class='col-md-6'>
              <h1 class='about_taital'>
                About <span class='us_text'>Us</span>
              </h1>
              <p class='about_text'>
                Flask IT Solutions is a cutting-edge software company
                specializing in seamlessly integrating insurance APIs for
                businesses. Our mission is to simplify insurance complexities
                with innovative solutions that enhance operational efficiency
                and elevate customer experiences. Our core services encompass
                comprehensive insurance API integration, enabling real-time
                access to diverse insurance products and services through secure
                data exchange. We remain innovation-driven, offering clients the
                latest tools for a competitive edge. Through collaboration, we
                understand and exceed client expectations, upholding rigorous
                security measures for data protection. Our solutions support
                startups to enterprises, adapting as businesses grow.
                <br />
                <br />
                Flask IT Solutions is more than a software company; we're
                strategic partners revolutionizing insurance integration for
                operational excellence, customer satisfaction, and enduring
                growth.
              </p>
              <div class='read_bt_1'>{/* <a href="#">Read More</a> */}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--about section end -->
      <!--services section start --> */}
      <div id='services' class='services_section layout_padding'>
        <div class='container'>
          <h1 class='service_taital'>
            <span class='our_text'>Our</span> Services
          </h1>
          <p class='service_text'>
            Software Development That Transforms Businesses
          </p>
          <div class='services_section_2'>
            <div class='row'>
              <div class='col-sm-4'>
                <div class='icon_1'>
                  <img src='./assets/images/icon-1.png' />
                </div>
                <h4 class='design_text'>Logo Design</h4>
                <p class='lorem_text'>
                  Create memorable logos that capture your brand
                </p>
                <div class='icon_1'>
                  <img src='./assets/images/icon-2.png' />
                </div>
                <h4 class='design_text'>Software Development</h4>
                <p class='lorem_text'>
                  Build custom software that solves your problems
                </p>
              </div>
              <div class='col-sm-4'>
                <div class='icon_3'>
                  <img src='./assets/images/icon-3.png' />
                </div>
                <h4 class='design_text'>Web Design</h4>
                <p class='lorem_text'>
                  Create user-friendly websites that drive traffic
                </p>
                <div class='read_bt_2'>{/* <a href="#">Read More</a> */}</div>
              </div>
              <div class='col-sm-4'>
                <div class='icon_1'>
                  <img src='./assets/images/icon-4.png' />
                </div>
                <h4 class='design_text'>Insurance API Integration</h4>
                <p class='lorem_text'>
                  We excel at integrating a diverse range of insurance APIs
                </p>
                <div class='icon_1'>
                  <img src='./assets/images/icon-5.png' />
                </div>
                <h4 class='design_text'>Social Media Work</h4>
                <p class='lorem_text'>
                  Manage your social media and grow your audience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--services section end -->
      <!--blog section start --> */}
      {/* <div id="blogs" class="blog_section layout_padding">
        <div class="container">
          <h1 class="blog_taital">
            <span class="our_text">Our</span> Blog
          </h1>
          <p class="blog_text">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered{" "}
          </p>
          <div class="services_section_2 layout_padding">
            <div class="row">
              <div class="col-md-4">
                <div class="box_main">
                  <div class="student_bg">
                    <img src="./assets/images/img-3.png" class="student_bg" />
                  </div>
                  <div class="image_15">
                    19
                    <br />
                    Feb
                  </div>
                  <h4 class="hannery_text">There are many variations</h4>
                  <p class="fact_text">
                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box_main">
                  <div class="student_bg">
                    <img src="./assets/images/img-4.png" class="student_bg" />
                  </div>
                  <div class="image_15">
                    19
                    <br />
                    Feb
                  </div>
                  <h4 class="hannery_text">There are many variations</h4>
                  <p class="fact_text">
                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box_main">
                  <div>
                    <img src="./assets/images/img-5.png" class="student_bg" />
                  </div>
                  <div class="image_15">
                    19
                    <br />
                    Feb
                  </div>
                  <h4 class="hannery_text">There are many variations</h4>
                  <p class="fact_text">
                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!--blog section end -->
      <!--newsletter section start --> */}
      <div class='newsletter_section'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6'>
              <h1 class='newsletter_text'>Subscribe Now </h1>
              <p class='tempor_text'>
                Subscribe to our newsletter for latest update{' '}
              </p>
            </div>
            <div class='col-md-6'>
              <div class='mail_bt_main'>
                <input
                  type='text'
                  class='mail_text'
                  placeholder='Enter E-Mail'
                  name='Enter Tour Mail'
                />
                <div class='subscribe_bt'>
                  <a href='#'>SUBSCRIBE</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--newsletter section end -->
      <!--client section start --> */}
      <div id='client' class='client_section layout_padding'>
        <div
          id='carouselExampleIndicators'
          class='carousel slide'
          data-ride='carousel'
        >
          <ol class='carousel-indicators'>
            <li
              data-target='#carouselExampleIndicators'
              data-slide-to='0'
              class='active'
            ></li>
            <li data-target='#carouselExampleIndicators' data-slide-to='1'></li>
            <li data-target='#carouselExampleIndicators' data-slide-to='2'></li>
          </ol>
          <div class='carousel-inner'>
            <div class='carousel-item active'>
              <div class='container'>
                <h1 class='blog_taital'>
                  <span class='tes_text'>Tes</span>timonial
                </h1>
                <div class='client_section_2 layout_padding'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='client_box active'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text active'>Amit Trivedi</h6>
                          <p class='consectetur_text active'>CEO</p>
                          <div class='quote_icon active'></div>
                        </div>
                        <p class='ipsum_text active'>
                          Working with Flask IT Solutions has been a
                          game-changer for us. Their innovative solutions and
                          expert team helped us streamline our processes and
                          enhance our productivity. We're incredibly satisfied
                          with the results!
                        </p>
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div class='client_box'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text'>Manmohan Singh</h6>
                          <p class='consectetur_text'>Marketing Head</p>
                          <div class='quote_icon'></div>
                        </div>
                        <p class='ipsum_text'>
                          I can't thank Flask IT Solutions enough for their
                          exceptional service. From the initial consultation to
                          the final product, their attention to detail and
                          commitment to excellence were evident. They turned our
                          vision into reality!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='container'>
                <h1 class='blog_taital'>
                  <span class='tes_text'>Tes</span>timonial
                </h1>
                <div class='client_section_2 layout_padding'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='client_box active'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text active'>Rajveer Singh</h6>
                          <p class='consectetur_text active'>CTO</p>
                          <div class='quote_icon active'></div>
                        </div>
                        <p class='ipsum_text active'>
                          Choosing Flask IT Solutions for our IT needs was one
                          of the best decisions we made. Their professionalism,
                          responsiveness, and technical expertise ensured that
                          our project was completed on time and within budget.
                          Highly recommended!
                        </p>
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div class='client_box'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text'>Rajesh Agnihotri</h6>
                          <p class='consectetur_text'>Product Manager</p>
                          <div class='quote_icon'></div>
                        </div>
                        <p class='ipsum_text'>
                          We've partnered with Flask IT Solutions for multiple
                          projects, and each time, they've exceeded our
                          expectations. Their ability to grasp complex
                          requirements and deliver tailored solutions is truly
                          impressive. We look forward to continued
                          collaboration!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='container'>
                <h1 class='blog_taital'>
                  <span class='tes_text'>Tes</span>timonial
                </h1>
                <div class='client_section_2 layout_padding'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <div class='client_box active'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text active'>Mohit Singh</h6>
                          <p class='consectetur_text active'>CEO</p>
                          <div class='quote_icon active'></div>
                        </div>
                        <p class='ipsum_text active'>
                          The team at Flask IT Solutions is a pleasure to work
                          with. Their creative approach to design and
                          development gave our website a fresh and modern look.
                          They listened to our ideas and turned them into a
                          stunning reality
                        </p>
                      </div>
                    </div>
                    <div class='col-md-6'>
                      <div class='client_box'>
                        <div class='left_main'>
                          <div class='image_6'>
                            <img src='./assets/images/avatar.png' />
                          </div>
                        </div>
                        <div class='right_main'>
                          <h6 class='magna_text'>Manish Saini</h6>
                          <p class='consectetur_text'>
                            Business Marketing Head
                          </p>
                          <div class='quote_icon'></div>
                        </div>
                        <p class='ipsum_text'>
                          When it comes to IT support, Flask IT Solutions is
                          top-notch. They've provided us with proactive
                          solutions that have minimized downtime and improved
                          our network security. Their expertise has been
                          invaluable to our business
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--client section end --> */}
      <div id='contact' class='contact_section layout_padding'>
        <div class='container'>
          <h1 class='touch_taital'>Contact Us</h1>
          <div class='contact_section_2'>
            <div class='row'>
              <div class='col-md-6'>
                <div class='email_text'>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='email-bt'
                      placeholder='Name'
                      name='Email'
                    />
                  </div>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='email-bt'
                      placeholder='Phone Numbar'
                      name='Email'
                    />
                  </div>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='email-bt'
                      placeholder='Email'
                      name='Email'
                    />
                  </div>
                  <div class='form-group'>
                    <textarea
                      class='massage-bt'
                      placeholder='Massage'
                      rows='5'
                      id='comment'
                      name='Massage'
                    ></textarea>
                  </div>
                  <div class='send_btn'>
                    <a href='#'>SEND</a>
                  </div>
                </div>
              </div>
              <div class='col-md-6'>
                <div class='map_main'>
                  <div class='map-responsive'>
                    <iframe
                      src='https://www.google.co.in/maps/place/Brij+Anukampa+Tower,+Ashok+Marg,+Patrakar+Colony,+C+Scheme,+Ashok+Nagar,+Jaipur,+Rajasthan+302001/@26.9150596,75.799543,17z/data=!3m1!4b1!4m6!3m5!1s0x396db401476a2589:0xde1ab1ea14318e10!8m2!3d26.9150548!4d75.8021179!16s%2Fg%2F1ydp99njh?entry=ttu'
                      style={{ border: '0', width: '100%' }}
                      width='600'
                      height='400'
                      frameborder='0'
                      allowfullscreen=''
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--footer section start --> */}
      <div class='footer_section layout_padding'>
        <div class='container'>
          <div class='address_main'>
            <div class='address_text'>
              <a href='#'>
                <img src='./assets/images/map-icon.png' />
                <span class='padding_left_15'>
                  Brij Anukampa Tower, C-scheme, Jaipur, 302001
                </span>
              </a>
            </div>
            <div class='address_text'>
              <a href='#'>
                <img src='./assets/images/call-icon.png' />
                <span class='padding_left_15'>+91 9024107900</span>
              </a>
            </div>
            <div class='address_text'>
              <a href='#'>
                <img src='./assets/images/mail-icon.png' />
                <span class='padding_left_15'>
                  support@flaskitsolutions.com
                </span>
              </a>
            </div>
          </div>
          <div class='footer_section_2'>
            <div class='row'>
              <div class='col-lg-3 col-sm-6'>
                <h4 class='link_text'>Useful link</h4>
                <div class='footer_menu'>
                  <ul>
                    <li>
                      <a href='#'>Home</a>
                    </li>
                    <li>
                      <a href='#about'>About</a>
                    </li>
                    <li>
                      <a href='#services'>Services</a>
                    </li>
                    <li>
                      <a href='#contact'>Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div class="col-lg-3 col-sm-6">
                <h4 class="link_text">web design</h4>
                <p class="footer_text">
                  Lorem ipsum dolor sit amet, consectetur adipiscinaliquaL
                  oreadipiscing{" "}
                </p>
              </div> */}
              <div class='col-lg-3 col-sm-6'>
                <h4 class='link_text'>social media</h4>
                <div class='social_icon'>
                  <ul>
                    <li>
                      <a href='#'>
                        <img src='./assets/images/fb-icon.png' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <img src='./assets/images/twitter-icon.png' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <img src='./assets/images/linkedin-icon.png' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <img src='./assets/images/youtub-icon.png' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class='col-lg-3 col-sm-6'>
                <h4 class='link_text'>Our Branchs</h4>
                <p class='footer_text_1'>
                  601, 6th Floor, Brij Anukampa Tower, Ashok Marg, C-scheme,
                  Jaipur, 302001
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--client section end --> */}
      {/* <!--copyright section start --> */}
      <div class='copyright_section'>
        <div class='container'>
          <p class='copyright_text'>
            Copyright 2023 All Right Reserved By
            <a href='https://flaskitsolutions.com'> Flask It Solutions</a>
          </p>
        </div>
      </div>
      {/* <!--copyright section end --> */}
    </>
  )
}

export default Navbar
